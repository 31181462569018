@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
}

table.no-pin th:first-child {
    position: relative;
}

.rmdp-container input {
    background-color: transparent;
    border: none;
    width: inherit;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.animate-opacity {
    animation: opacity-disappear 0.3s forwards ease-in-out;
}

@keyframes opacity-disappear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
